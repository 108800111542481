import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, Modal, Table, Button, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import { RootState } from "../store";
import { EHR, HealthieProvider, OmniPatient, Organization } from "../types";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { formatIsoToCustomDateStringWithEEEE } from "../utils/utils";
import { usePostHog } from "posthog-js/react";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import FilterWaitlistModal from "./FilterWaitlistModal";
import { TransparentButtonVariant1 } from "../subcomponents/CustomButton";
import { DateTime } from "luxon";
import IosShareIcon from "@mui/icons-material/IosShare";
import { Parser } from "@json2csv/plainjs";

type ViewWaitlistModalProps = {
  open: boolean;
  onClose: () => void;
};

const ViewWaitlistModal: React.FC<ViewWaitlistModalProps> = ({ open, onClose }) => {
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);

  const patients: OmniPatient[] = useSelector((state: RootState) => state.patients.patients);
  const healthieProviders = useSelector((state: RootState) => state.healthieIntegration.providers).map((provider) => ({
    id: provider.id,
    name: `${provider.firstName} ${provider.lastName}`,
  }));
  const webptProviders = useSelector((state: RootState) => state.webptIntegration.providers);
  const providers = [...healthieProviders, ...webptProviders];
  const posthog = usePostHog();

  const loadingPatients: boolean = useSelector((state: RootState) => state.patients.loadingFetchPatients);

  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const [filterOpen, setFilterOpen] = useState<boolean>(false);

  const [selectedProviders, setSelectedProviders] = useState<any[]>(providers);
  const [dateRange, setDateRange] = useState<[DateTime | null, DateTime | null]>([null, null]);

  const [selectedPatients, setSelectedPatients] = useState<OmniPatient[]>([]);

  const handleSort = (field: string) => {
    if (sortBy === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortDirection("asc");
    }
  };

  // Filter patients by selected provider IDs
  const handleFilterProviders = (providers: HealthieProvider[]) => {
    setSelectedProviders(providers);
  };

  // Filter patients by selected date range
  const handleFilterDates = (selectedDateRange: [DateTime | null, DateTime | null]) => {
    setDateRange(selectedDateRange);
  };

  useEffect(() => {
    // Apply filtering by providers and dates
    if (patients.length === 0) {
      return;
    }

    let filteredPatients: OmniPatient[] = patients.filter((patient: OmniPatient) => patient.waitlist.availableDates.length > 0);

    // Filter by selected providers
    if (selectedProviders.length > 0) {
      const providerIds: string[] = selectedProviders.map((provider) => provider.id);
      filteredPatients = filteredPatients.filter((patient: OmniPatient) =>
        (patient[EHR.healthie]?.providerIds ?? patient[EHR.webpt]?.webptProviderIds ?? []).some((id: string) => providerIds.includes(id))
      );
    }

    // Filter by date range
    if (dateRange[0] && dateRange[1]) {
      // Create a copy of the dateRange array and sort it
      const sortedDateRange = [...dateRange].sort((a: DateTime | null, b: DateTime | null) => {
        if (a && b) {
          return a.toMillis() - b.toMillis();
        } else {
          return 0;
        }
      });

      const [start, end] = sortedDateRange.map((d) => d?.startOf("day")); // Compare by the day only, ignore time

      if (start && end) {
        filteredPatients = filteredPatients.filter((patient: OmniPatient) => {
          const availableDates: DateTime[] = patient.waitlist.availableDates.map(
            (date: string) => DateTime.fromISO(date, { setZone: true }).startOf("day") // Ensure we are using just the date for comparison
          );

          return availableDates.some((date: DateTime) => date >= start && date <= end);
        });
      }
    }

    // Apply sorting
    const sortedPatients = [...filteredPatients].sort((a: OmniPatient, b: OmniPatient) => {
      const order = sortDirection === "asc" ? 1 : -1;

      switch (sortBy) {
        case "firstName":
          return a.patient.firstName.localeCompare(b.patient.firstName) * order;
        case "lastName":
          return a.patient.lastName.localeCompare(b.patient.lastName) * order;
        case "providerNames":
          const firstProviderNameA: string =
            providers.find((provider) => provider.id === (a[EHR.healthie]?.providerIds?.[0] ?? a[EHR.webpt]?.webptProviderIds?.[0] ?? ""))?.name ||
            "";

          const firstProviderNameB: string =
            providers.find((provider) => provider.id === (b[EHR.healthie]?.providerIds?.[0] ?? b[EHR.webpt]?.webptProviderIds?.[0] ?? ""))?.name ||
            "";

          return firstProviderNameA.localeCompare(firstProviderNameB) * order;
        case "availableDates":
          const countA = a.waitlist.availableDates.length;
          const countB = b.waitlist.availableDates.length;
          return (countA - countB) * order;
        default:
          return 0;
      }
    });

    setSelectedPatients(sortedPatients);
  }, [patients, selectedProviders, dateRange, sortBy, sortDirection, loadingPatients, providers]);

  const exportPatientsToCSV = (patients: OmniPatient[]) => {
    try {
      if (patients.length === 0) {
        return;
      }

      const parser = new Parser();

      const csvPatients = patients
        .filter((patient: OmniPatient) => patient.waitlist.availableDates.length > 0)
        .map((patient: OmniPatient) => {
          return {
            "First Name": patient.patient.firstName,
            "Last Name": patient.patient.lastName,
            "Provider Names": (patient?.[EHR.healthie]?.providerIds ?? patient?.[EHR.webpt]?.webptProviderIds ?? [])
              .map((providerId) => {
                const provider = providers.find((provider) => provider.id === providerId);
                return provider?.name ?? "";
              })
              .filter(Boolean)
              .join(", "),
            "Available Dates": patient.waitlist.availableDates.map((date: string) => DateTime.fromISO(date).toFormat("yyyy-MM-dd")).join(", "),
          };
        });

      const csv = parser.parse(csvPatients);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `exported_patients.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting patients to CSV", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          overflowY: "auto", // Scroll if the content overflows the screen
          height: "100vh", // Full viewport height for centering
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start", // Start from the top of the screen
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim background for focus
          padding: "20px", // Add some padding so the modal is not touching screen edges
        }}
      >
        <Box
          sx={{
            backgroundColor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxWidth: 800,
            width: "95%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography variant="h5" gutterBottom>
                Waitlist {selectedPatients.length > 0 ? `(${selectedPatients.length})` : ""}
              </Typography>

              <TransparentButtonVariant1
                sx={{ marginTop: "-10px" }}
                onClick={() => {
                  if (filterOpen) {
                    posthog?.capture("[PENCILED] User closed waitlist filter modal", {
                      orgName: organization?.name,
                      organizationId: organization?.organizationId,
                    });
                  } else {
                    posthog?.capture("[PENCILED] User opened waitlist filter modal", {
                      orgName: organization?.name,
                      organizationId: organization?.organizationId,
                    });
                  }

                  setFilterOpen(!filterOpen);
                }}
              >
                Filter
              </TransparentButtonVariant1>

              <IconButton
                sx={{ marginTop: "-10px" }}
                onClick={() => {
                  exportPatientsToCSV(selectedPatients);
                }}
              >
                <IosShareIcon />
              </IconButton>
              {(selectedProviders.length !== providers.length || (dateRange[0] !== null && dateRange[1] !== null)) && (
                <Tooltip title="Reset filters" placement="top">
                  <Typography
                    onClick={() => {
                      posthog?.capture("[PENCILED] User reset waitlist filters", {
                        orgName: organization?.name,
                        organizationId: organization?.organizationId,
                      });

                      setSelectedProviders(providers);
                      setDateRange([null, null]);
                    }}
                    variant="caption"
                    sx={{
                      marginTop: "-10px",
                      color: "inherit",
                      "&:hover": {
                        color: "red",
                        textDecoration: "underline",
                      },
                    }}
                  >
                    Filters applied
                  </Typography>
                </Tooltip>
              )}
            </Box>

            <Tooltip title="Close">
              <IconButton
                onClick={() => {
                  posthog?.capture("[PENCILED] User closed waitlist modal via upper backdrop", {
                    orgName: organization?.name,
                    organizationId: organization?.organizationId,
                  });

                  onClose();
                }}
                sx={{ right: 10 }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {filterOpen && (
            <FilterWaitlistModal
              open={filterOpen}
              dateRange={dateRange}
              setDateRange={handleFilterDates}
              providers={selectedProviders}
              setProviderFilter={handleFilterProviders}
              onClose={() => setFilterOpen(false)}
            />
          )}

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Tooltip title="Sort by first name">
                    <TableSortLabel
                      active={sortBy === "firstName"}
                      direction={sortDirection}
                      onClick={() => {
                        posthog?.capture("[PENCILED] User sorted waitlist by first name", {
                          sortBy: "firstName",
                          sortDirection: sortDirection,
                          orgName: organization?.name,
                          organizationId: organization?.organizationId,
                        });
                        handleSort("firstName");
                      }}
                    >
                      First Name
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Sort by last name">
                    <TableSortLabel
                      active={sortBy === "lastName"}
                      direction={sortDirection}
                      onClick={() => {
                        posthog?.capture("[PENCILED] User sorted waitlist by last name", {
                          sortBy: "lastName",
                          sortDirection: sortDirection,
                          orgName: organization?.name,
                          organizationId: organization?.organizationId,
                        });

                        handleSort("lastName");
                      }}
                    >
                      Last Name
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Sort by provider name">
                    <TableSortLabel
                      active={sortBy === "providerNames"}
                      direction={sortDirection}
                      onClick={() => {
                        posthog?.capture("[PENCILED] User sorted waitlist by provider name", {
                          sortBy: "providerNames",
                          sortDirection: sortDirection,
                          orgName: organization?.name,
                          organizationId: organization?.organizationId,
                        });

                        handleSort("providerNames");
                      }}
                    >
                      Provider Names
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Sort by available days">
                    <TableSortLabel
                      active={sortBy === "availableDates"}
                      direction={sortDirection}
                      onClick={() => {
                        posthog?.capture("[PENCILED] User sorted waitlist by available dates", {
                          sortBy: "availableDates",
                          sortDirection: sortDirection,
                          orgName: organization?.name,
                          organizationId: organization?.organizationId,
                        });

                        handleSort("availableDates");
                      }}
                    >
                      Available Dates
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loadingPatients && !selectedPatients.length && (
                <TableRow>
                  <TableCell colSpan={4} sx={{ padding: 0, border: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "300px", // Set desired height for vertical centering
                        width: "100%", // Ensure the div takes full width
                      }}
                    >
                      <Typography variant="body1" sx={{ textAlign: "center" }}>
                        No patients found
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              )}

              {loadingPatients && (
                <TableRow>
                  <TableCell colSpan={4} sx={{ padding: 0, border: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "300px", // Set desired height for vertical centering
                        width: "100%", // Ensure the div takes full width
                      }}
                    >
                      <LoadingWithMessage
                        message={"Loading patients"}
                        size={20}
                        customStyles={{ color: "black" }} // Ensure black text for the message
                      />
                    </div>
                  </TableCell>
                </TableRow>
              )}

              {selectedPatients
                .filter((patient: OmniPatient) => patient.waitlist.availableDates.length > 0)
                .map((patient: OmniPatient, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{patient.patient.firstName}</TableCell>
                    <TableCell>{patient.patient.lastName}</TableCell>
                    <TableCell>
                      {(patient?.[EHR.healthie]?.providerIds ?? patient?.[EHR.webpt]?.webptProviderIds ?? [])
                        .map((providerId) => {
                          const provider = providers.find((provider) => provider.id === providerId);
                          return provider?.name ?? "";
                        })
                        .filter(Boolean)
                        .join(", ")}
                    </TableCell>
                    <TableCell>
                      {patient.waitlist.availableDates.map((date: string) => formatIsoToCustomDateStringWithEEEE(date)).join(", ")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          <Box sx={{ mt: 3, display: "flex", justifyContent: "right" }}>
            <Button
              onClick={() => {
                posthog?.capture("[PENCILED] User closed waitlist modal via lower button", {
                  orgName: organization?.name,
                  organizationId: organization?.organizationId,
                });
                onClose();
              }}
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewWaitlistModal;
