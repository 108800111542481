import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import authReducer from "./slices/AuthSlice";
import sessionReducer from "./slices/SessionSlice";
import agentsReducer from "./slices/AgentSlice";
import callsReducer from "./slices/CallSlice";
import scheduledCallsReducer from "./slices/ScheduledCallSlice";
import healthieIntegrationReducer from "./slices/HealthieIntegrationSlice";
import uploadCSVReducer from "./slices/UploadCSVSlice";
import apiKeysReducer from "./slices/KeysSlice";
import webhookReducer from "./slices/WebhookSlice";
import questionAndAnswersSlice from "./slices/QuestionAndAnswerSlice";
import agentTemplatesSlice from "./slices/AgentTemplates";
import waitlistRunsSlice from "./slices/WaitlistRunsSlice";
import calendalySlice from "./slices/CalendlySlice";
import patientSlice from "./slices/PatientSlice";
import waitlistSlotsSlice from "./slices/WaitlistSlotsSlice";
import { useDispatch } from "react-redux";
import webptIntegrationSlice from "./slices/WebptIntegrationSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["calls", "agents"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  session: sessionReducer,
  agents: agentsReducer,
  calls: callsReducer,
  scheduledCalls: scheduledCallsReducer,
  healthieIntegration: healthieIntegrationReducer,
  uploadCSV: uploadCSVReducer,
  apiKeys: apiKeysReducer,
  webhooks: webhookReducer,
  questionsAndAnswers: questionAndAnswersSlice,
  agentTemplates: agentTemplatesSlice,
  waitlistRuns: waitlistRunsSlice,
  waitlistSlots: waitlistSlotsSlice,
  calendly: calendalySlice,
  patients: patientSlice,
  webptIntegration: webptIntegrationSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

let isPurging = false; // Flag to indicate if purging is in progress

// let unsubscribe: (() => void) | undefined;

// Listen to signOut action and purge the state of calls and agents slices
const unsubscribe = store.subscribe(() => {
  const state = store.getState();
  if (state.auth.user === null && !isPurging) {
    // Check if not already purging
    isPurging = true; // Set flag to indicate purging is in progress
    persistor.purge(); // Clear the Redux store
    unsubscribe(); // Unsubscribe from the store
  }
});
