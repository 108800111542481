import { FunctionComponent, useEffect, useState } from "react";
import styles from "./Calendar.module.scss";
import Weekdays from "./utils/Weekdays";
import { CalendarType, TileDisabledFunc } from "./utils/types";
import { CALENDAR_TYPE_LOCALES, CALENDAR_TYPES } from "./utils/constants";
import Days from "./utils/Days";
import leftArrowIcon from "./assets/left-arrow.svg";
import rightArrowIcon from "./assets/right-arrow.svg";
import {
  SelectedDatesProvider,
  useSelectedDatesContext,
} from "./utils/hooks/useSelectedDates";

function getCalendarTypeFromLocale(locale: string | undefined): CalendarType {
  if (locale) {
    for (const [calendarType, locales] of Object.entries(
      CALENDAR_TYPE_LOCALES
    )) {
      if (locales.includes(locale)) {
        return calendarType as CalendarType;
      }
    }
  }

  return CALENDAR_TYPES.GREGORY;
}

export interface CalendarProps {
  dates: Date[];
  onDatesChange: (dates: Date[]) => void;
  onCancel?: () => void;
  /**
   * Type of calendar that should be used. Can be `'gregory`, `'hebrew'`, `'islamic'`, `'iso8601'`. Setting to `"gregory"` or `"hebrew"` will change the first day of the week to Sunday. Setting to `"islamic"` will change the first day of the week to Saturday. Setting to `"islamic"` or `"hebrew"` will make weekends appear on Friday to Saturday.
   *
   * @example 'iso8601'
   */
  calendarType?: CalendarType;
  formatShortWeekday?: (locale: string | undefined, date: Date) => string;
  formatWeekday?: (locale: string | undefined, date: Date) => string;
  locale?: string;
  activeStartDate?: Date;
  showNeighboringMonth?: boolean;
  shouldDisableDate?: TileDisabledFunc;
  disableWeekdaySelection?: boolean;
  disableGestureSelection?: boolean;
}

const MonthView: FunctionComponent<CalendarProps> = (props) => {
  const {
    dates,
    onDatesChange,
    onCancel,
    calendarType = getCalendarTypeFromLocale(props.locale),
    formatShortWeekday,
    formatWeekday,
    locale,
    activeStartDate: _activeStartDate,
    showNeighboringMonth,
    shouldDisableDate,
    disableGestureSelection,
    disableWeekdaySelection
  } = props;

  const { selectedDatesSet, setSelectedDatesSet } = useSelectedDatesContext();

  const [activeStartDate, setActiveStartDate] = useState<Date>(
    _activeStartDate ||
      new Date(new Date().getFullYear(), new Date().getMonth())
  );

  useEffect(() => {
    setSelectedDatesSet(new Set(dates.map((date) => date.toISOString())));
  }, [dates, setSelectedDatesSet]);

  return (
    <div className={styles.wrapper} data-testid="calendar">
      <div className={styles.navigation}>
        <button
          onClick={() =>
            setActiveStartDate(
              new Date(
                activeStartDate.getFullYear(),
                activeStartDate.getMonth() - 1
              )
            )
          }
          className={styles["navigation-button"]}
        >
          <img src={leftArrowIcon} alt="Previous" />
        </button>
        {activeStartDate?.toLocaleDateString(locale, {
          month: "short",
          year: "numeric",
        })}
        <button
          onClick={() =>
            setActiveStartDate(
              new Date(
                activeStartDate.getFullYear(),
                activeStartDate.getMonth() + 1
              )
            )
          }
          className={styles["navigation-button"]}
        >
          <img src={rightArrowIcon} alt="Next" />
        </button>
      </div>
      <div className={styles["calendar-body"]}>
        <Weekdays
          calendarType={calendarType}
          formatShortWeekday={formatShortWeekday}
          formatWeekday={formatWeekday}
          locale={locale}
          activeStartDate={activeStartDate}
          shouldDisableDate={shouldDisableDate}
          disableWeekdaySelection={disableWeekdaySelection}
        />
        <Days
          calendarType={calendarType}
          activeStartDate={activeStartDate}
          showNeighboringMonth={showNeighboringMonth}
          shouldDisableDate={shouldDisableDate}
          disableGestureSelection={disableGestureSelection}
        />
      </div>
      <div className={styles.buttons}>
        <button
          className={[styles.clear, selectedDatesSet.size && styles.active].join(" ")}
          onClick={() => {
            setSelectedDatesSet(new Set());
            onDatesChange([]);
          }}
          type="button"
        >
          Clear
        </button>
        <div className={styles["ok-cancel"]}>
          <button
            className={styles.cancel}
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className={[
              styles.ok,
              !selectedDatesSet.size && styles.disabled,
            ].join(" ")}
            disabled={!selectedDatesSet.size}
            type="button"
            onClick={() => {
              onDatesChange(
                Array.from(selectedDatesSet).map((date) => new Date(date))
              );
            }}
          >
            Add Dates
          </button>
        </div>
      </div>
    </div>
  );
};

const Calendar: FunctionComponent<CalendarProps> = (props) => {
  return (
    <SelectedDatesProvider>
      <MonthView {...props} />
    </SelectedDatesProvider>
  );
};

export default Calendar;
