import { MouseEvent, useEffect, useRef, useState } from "react";
import styles from "./DatePicker.module.scss";
import Calendar, { CalendarProps } from "../calendar/Calendar";
import calendarIcon from "../calendar/assets/calendar.svg";

interface DatePickerProps extends CalendarProps {
  format?: string;
  responsive?: boolean;
  disabled?: boolean;
  className?: string;
  iconAtLeft?: boolean;
  /**
   * Defaults to left
   */
  dropdownAlignment?: "left" | "right";
  placeholder?: string;
  content?: React.ReactNode;
  dropdownTop?: boolean;
  sx?: React.CSSProperties;
}

const CustomDatePicker = (props: DatePickerProps) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const {
    dates,
    onDatesChange: onChange,
    responsive,
    disabled,
    className,
    iconAtLeft,
    placeholder,
    dropdownAlignment = "left",
    content,
    dropdownTop,
    sx,
    ...calendarProps
  } = props;

  const dropDownRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleClose = (e: globalThis.MouseEvent) => {
    const dropdown = dropDownRef.current;

    if (!dropdown || !dropdown.contains(e.target as Element)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);
    return () => window.removeEventListener("click", handleClose);
  }, []);

  const handleSelectClick = (e: MouseEvent<HTMLDivElement>) => {
    if (disabled) {
      return;
    }

    const select = selectRef.current;
    if (select && select.contains(e.target as Element)) {
      setShowDropdown(!showDropdown);
    }
  };

  const handleDateSelect = (dates: Date[]) => {
    onChange?.(dates);
    if (dates.length) {
      setShowDropdown(false);
    }
  };

  const shownValue = dates.length ? `${dates.length} days selected` : "";

  return (
    <div
      className={[styles["select-wrapper"], showDropdown ? styles.active : "", responsive && styles.responsive, className].join(" ")}
      ref={dropDownRef}
      onClick={handleSelectClick}
      style={sx}
    >
      <div className={[styles["main-content"], iconAtLeft && styles["icon-at-left"]].join(" ")} ref={selectRef}>
        {content ? (
          <div>{content}</div>
        ) : (
          <>
            {iconAtLeft && <img alt="calendar" className={styles.icon} src={calendarIcon} />}
            {shownValue ? (
              <span className={styles["main-text"]}>{shownValue}</span>
            ) : (
              <span className={styles.placeholder}>{placeholder || "Select date"}</span>
            )}
            {!iconAtLeft && <img alt="calendar" className={styles.icon} src={calendarIcon} />}
          </>
        )}
      </div>
      <div
        className={[styles.dropdown, showDropdown && styles["show-dropdown"], styles[dropdownAlignment], dropdownTop && styles["on-top"]].join(" ")}
      >
        <Calendar dates={dates} onDatesChange={handleDateSelect} onCancel={() => setShowDropdown(false)} {...calendarProps} />
      </div>
    </div>
  );
};

export default CustomDatePicker;
