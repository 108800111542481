import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";

const SelectedDatesContext = createContext<{
  selectedDatesSet: Set<string>;
  setSelectedDatesSet: (selectedDatesSet: Set<string>) => void;
}>({
  selectedDatesSet: new Set(),
  setSelectedDatesSet: () => {},
});

export const SelectedDatesProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [selectedDatesSet, setSelectedDatesSet] = useState<Set<string>>(
    new Set()
  );

  const contextValue = useMemo(
    () => ({ selectedDatesSet, setSelectedDatesSet }),
    [selectedDatesSet, setSelectedDatesSet]
  );

  return (
    <SelectedDatesContext.Provider value={contextValue}>
      {children}
    </SelectedDatesContext.Provider>
  );
};

export const useSelectedDatesContext = () => {
  const { selectedDatesSet, setSelectedDatesSet } =
    useContext(SelectedDatesContext);

  return { selectedDatesSet, setSelectedDatesSet };
};
