import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Tooltip,
  Switch,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChatIcon from "@mui/icons-material/Chat";
import { Webhook, FrontendUser, LogType } from "../types";
import { RootState, useAppDispatch } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../subcomponents/CustomButton";
import {
  clearWebhooks,
  refreshWebhook,
  addWebhook,
  fetchWebhooks,
  deleteWebhook,
  toggleWebhookActivity,
  toggleWebhookRecording,
} from "../slices/WebhookSlice";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { api } from "../utils/utils";
import { Colors } from "../Colors";
import { DateTime } from "luxon";
import { LogItem } from "../types";
import { Link } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";

const Logs: React.FC = () => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [logs, setLogs] = useState<LogItem[]>([]);

  const [filter, setFilter] = useState<LogType[]>(Object.values(LogType));

  const filteredLogs = logs.filter((log) => filter.includes(log.logType));

  const dispatch = useAppDispatch();

  const fetchLogs = async () => {
    if (!user || !user.token) {
      return;
    }

    setLoading(true);
    const response = await api.get(`/log-items`, user.token);
    console.log(response);
    setLogs(response.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchLogs();
  }, [user?.token]);

  return (
    <Box
      sx={{
        marginTop: { xs: "75px", sm: 0, color: "#FFF" },
        "& .MuiOutlinedInput-root": { color: "#FFFFFF" },
        "& .MuiInputLabel-root": { color: Colors.info },
        "& .MuiInputBase-input": { color: "#FFFFFF" },
        "& .MuiSvgIcon-root": { color: "#FFF" },
        "& .MuiAutocomplete-paper": { backgroundColor: Colors.bg3, margin: 0 },
        "& .MuiAutocomplete-listbox": { backgroundColor: Colors.bg3 },
        "& .MuiTypography-body1": { color: "#FFF" },
        "& .MuiTypography-subtitle2": { color: "#FFF" },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
        <Typography variant="h6">Log Items</Typography>
      </Box>
      {loading && <LoadingWithMessage message="Fetching logs..." size={60} />}
      {logs.length === 0 && !loading && <Typography variant="body1">No logs found.</Typography>}
      {logs.length > 0 && !loading && (
        <>
          {Object.values(LogType).map((logType) => (
            <>
              <Typography variant="body1">
                {logType}
                <Checkbox
                  key={logType}
                  checked={filter.includes(logType)}
                  onChange={() => {
                    if (filter.includes(logType)) {
                      setFilter(filter.filter((f) => f !== logType));
                    } else {
                      setFilter([...filter, logType]);
                    }
                  }}
                />
              </Typography>
            </>
          ))}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">Time</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Link</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Description</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLogs.map((log, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography>{log.createdAt ? DateTime.fromISO(log.createdAt).toFormat("MM/dd HH:mm") : "UNKNOWN TIME"}</Typography>
                  </TableCell>
                  <TableCell>
                    <Link to={`/dashboard/patients/${log.patientId}`} style={{ textDecoration: "none" }}>
                      <Tooltip title="View Chat">
                        <IconButton>
                          <ChatIcon />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ display: "flex", alignItems: "center" }}>
                      {log.logType === LogType.actionItem && <WarningIcon style={{ marginRight: 4 }} />}
                      {log.logDescription}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  );
};

export default Logs;
